import {type MaybeRefOrGetter} from 'vue';
import type {SeoFallback} from '~/interfaces/seo';
import {useSeo} from '~/composables/useSeo';


export const useEtherSeo = (
    data: MaybeRefOrGetter,
    opts: SeoFallback,
    activeSite?: any
) => {
    const config = useRuntimeConfig();
    const cmsSeo = useSeo(toValue(data)?.seo, data, opts, activeSite);

    const seo = computed(() => ({
        ...cmsSeo.value,
        title: cmsSeo.value?.title ?? opts?.seoTitle ?? config.public?.appName,
        htmlAttrs: {
            lang: 'en',
        },
    }));

    if (!seo.value.title) {
        console.warn('SEO title is empty');
    } else {
        useHead(seo);
    }
};
